.Details-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 5rem;
  right: 0px;
  bottom: 0px;
  z-index: 4;
  left: 0px;
  background-color: white;
  overflow-y: scroll;
}