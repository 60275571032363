.vignette {
  width: 100%;
}

.card-grid {
  transition: 0.4s;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;

}

@media (min-width: 1000px) {
  .card-grid:hover {
    transform: scale(1.04);
    filter: grayscale(0%);
  }

  .card-grid:hover .title-card {
    opacity: 1;
    transform: translate(0, 0);
  }

  .title-card {
    position: absolute;
    width: inherit;
    text-decoration: none;
    color: white;
    bottom: 10%;
    font-weight: 500;
    font-size: 2rem;
    width: 100%;
    text-align: center;
    opacity: 0;
    transform: translate(0, -6vw);
    transition: 0.7s;
  }

}

@media (max-width: 1000px) {
  .title-card {
    position: absolute;
    text-align: center;
    text-decoration: none;
    color: white;
    width: 100%;
    font-weight: 400;
    font-size: 1.2rem;
    bottom: 2rem;
    transition: 1s;
  }


}

.sub-card {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: 2s;
}

.description {
  text-align: left;
  text-justify: auto;
  padding-left: 60px;
  scrollbar-width: none;
  padding-right: 60px;
}

.title-description {
  font-weight: lighter;
  margin: 0;
  padding-top: 20px;
}



.title-card-container {
  max-width: 100%;
  text-align: left;
}