body {
  background-image: url('IMG_8097 (2).jpg');
  background-attachment: fixed;
  background-position: bottom;
  margin: 0px;
  background-size: cover;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overflow-y: hidden;
  font-family: 'Comfortaa' !important
    /* Hide vertical scrollbar */
}

html {
  overflow: scroll;
  overflow-x: hidden;
  height: 100% !important;
}

.mx-auto>p {
  margin-bottom: .5rem;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

#root {
  height: 100%;
}

@media (min-width :1000px) {
  body {
    height: 100%;
  }

}