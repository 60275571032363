/* Add your styles in FullScreenCarousel.css or use a styling solution of your choice */

.image-display {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.button-leave-fullscreen {
    position: absolute;
}
.image-display{
    max-height: 90dvh;
}
/* Add any additional styles for the close button or carousel content */