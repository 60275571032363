.a-propos-container {
    width: 100%;
    text-align: justify;
    margin-top: 2rem;
}

.container-page {
    width: 100%;
    padding-left: 8rem;
    padding-right: 8rem;
    background: white;
    padding-top: 6rem;
    padding-bottom: 6rem;
}

@media (max-width: 1000px) {
    .container-page {
        padding-left: 2rem;
        padding-right: 2rem;

    }

}