@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&family=Roboto+Condensed:ital,wght@0,400;0,700;1,400&display=swap');

.App {
  height: 100vh;
  overflow: auto;
  align-items: center;
  justify-content: center;
}


.navbar-logo>svg {
  width: 10rem;
}

.nav-title {
  color: black;
  text-decoration: none;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
}

.logo-container {
  display: flex;
  width: 4.5rem;
}

.logo-container>img {
  margin-right: 0.5rem;
}

.logo-img {
  height: 90%;
  margin: auto;
}

.nav-links {
  color: black;
  align-items: center;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.5rem;
  height: 100%;
  transition: 0.3s ease-in-out;
}

.nav-links:hover {
  color: rgb(180, 145, 83);
  text-decoration: none;
  transform: translate(0px, -4px);
}

.arrow-link {
  position: relative;
}

.arrow-icon {
  position: absolute;
  right: 10%;
}

.title {
  text-align: center;
  font-size: 2rem;
  letter-spacing: 0.13rem !important;
  font-weight: 600;


}

.mt-3 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 0 !important;
}

.subtitle {
  text-align: center;
  font-size: 0.9rem;
  letter-spacing: -0.02rem !important;
  text-transform: uppercase;
}

.home-logo {
  width: 150px;
  margin-left: 3rem;
  display: flex;
  max-height: 100%;
  padding: 12px;
}

.home-logo>svg>g {
  fill: black;
}

.home-logo>svg {
  width: 120px;
}

.home-logo-img {
  height: 5rem;
  width: auto;
  filter: sepia(2);
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}


.top-container {
  display: flex;
  position: fixed;
  z-index: 3;
}

.menu-icon {
  display: none;
  right: 5vw;
  transform: translate(-100%);
  position: absolute;
  width: 35px;
}

.menu-icon-item {

  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
}


.navbar-ul {
  display: flex;
  width: 60%;
  align-items: center;
  align-self: center;
  margin-left: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-top: auto;
  padding: 0;
  height: 100%;
  flex-wrap: wrap;
}

.logo-footer {
  height: 10rem;

}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

.navbar-logo {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  height: 100%;
  filter: grayscale();
}



.NavbarItems {
  width: 100vw;
  background-color: white;
  display: flex;
  align-items: center;
  position: fixed;
  height: 5rem;
  flex-direction: row;
  transition: 1s;
}

.NavbarItems:hover .navbar-logo>svg {
  filter: invert(0%) sepia(0%) saturate(7482%) hue-rotate(185deg) brightness(96%) contrast(101%);
  ;
}

.NavbarItems:hover .bar-navbar-container {
  opacity: 1;
}

.bar-navbar-container {
  width: 100%;
  position: fixed;
  top: calc(5rem - 1px);
  transition: 1s;
  height: 1px;
  background-color: transparent;
}

.bar-navbar {
  height: 1px;
  background-color: black;
  margin-left: 4.5rem;
  margin-right: 4.5rem;
}

.title-container {
  display: flex;
  flex-direction: column;
  margin: auto;
}



.navbar-li {
  font-size: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  color: black;
  align-items: center;
}

@media (max-width: 1000px) and (orientation:portrait) {

  .navbar-ul {
    transition: 1s;
    flex-direction: row;
    position: absolute;
    background-color: white;
    width: 100%;
    top: 5rem;
    padding-left: 0;
    height: calc(20vh - 1rem);
  }

  .bar-navbar {
    height: 1px;
    background-color: black;
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .navbar-li {
    background-color: white;
    width: 100%;
  }

  .menu-icon {
    display: inline-block;
    right: 2rem;
    transition: 1s;
    transform: translateX(-5vw);
  }
}