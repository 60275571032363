.contact-container {
    top: 4rem;
    align-items: center;
    width: 100vw;
    height: calc(100dvh - 4rem);
    padding-top: 4rem;
    background: white;
}

.grid-container {
    margin: auto;
    padding-top: 15%;
    width: 50%;
    grid-gap: 10px;
    margin-bottom: 20vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.linkdin {
    align-items: center;
    display: flex;
}

.titre {
    text-align: center;

    word-break: break-word;
    font-size: calc(2vw + 8px);
    width: 25vw;

}

.contact-data {
    width: 25vw;
    text-align: start;
    font-size: calc(2vw + 8px);
    word-break: break-word;
}