@media (min-width :1000px) {
    .detail-container {
        display: flex;
        height: 90%;
        margin-bottom: 5%;
    }

    .carousel-root {
        width: 100%;
    }

    .detail-ville {
        display: flex;
        margin-right: 20px;
        flex-direction: column;
    }

    .annee-p {
        margin: auto;
        text-align: center;
    }

    .carousel-container {
        width: 50%;
        margin-left: 1rem;
        margin-right: 1rem;
        display: flex;
    }

    .carousel-root {
        margin: auto;
    }

    .text-container {

        margin-right: 1rem;
    }

}

.carousel-control-next-icon::after,
.carousel-control-prev-icon::after {
    color: white;
    -webkit-text-stroke: 1px black;
    /* width and color */

    font-family: sans;
}



.Details-container {
    padding: auto;
}

.detail-title {
    padding-top: 1rem;
    text-align: center;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 20%;
}

.text-container {
    width: 50%;
    overflow: hidden;
    overflow-y: scroll;
    align-items: center;
}

.description {
    font-size: 1.1rem;
    text-align: start;
    max-height: 35vh;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid gray !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid gray !important;
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(255, 255, 255, .5) !important;
}

.img-container {
    width: 40%;
    margin: auto;
}

.slick-slider {

    bottom: 20%;
}

.leave {
    position: absolute;
    top: 5%;
    right: 4%;
    width: auto;
    z-index: 1;
    height: 1.5rem;
}

.img-carousel {
    height: 100%;
    width: auto;
    margin: auto;
}



.carousel {
    width: 50vw;
}


.detail-ville {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

.slide {
    display: flex;
}

.ville-p {
    margin: auto;
}

.image-carousel {
    width: 40vw;
    margin: auto;
}




@media (max-width: 1000px) {
    .detail-ville {
        display: flex;
        flex-direction: column;
    }

    .detail-container {
        display: flex;
        flex-direction: column;
        height: calc(100dvh - 4rem);
    }

    .annee-p {
        margin: auto;
        text-align: center;
    }

    .text-container {
        width: 100%;
    }

    .leave {
        top: 1vh;
        right: 3vw;
        height: 5vw;
        width: 5vw;
    }


    .carousel .slide img,
    .carousel img {
        width: auto !important;
    }

    .carousel.carousel-slider {
        margin: auto !important;
        max-height: 30vh;
    }

    img {
        width: auto;
        max-height: 30vh;
        max-width: 100%;
    }

    .carousel-container {
        margin: 1rem;
    }


    .carousel-root {
        max-height: 30vh;
    }

    .detail-title {
        font-size: 1.5rem;
    }

    .description {
        font-size: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        word-break: normal;
        text-align: justify;
    }

    .img-container {
        height: 40vh;
        width: auto;
        max-width: 80%;
    }
}